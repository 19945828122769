.field-row.field-row-msis-id {
  grid: auto-flow / 1fr 0.5fr 2fr;
  display: grid;
  width: 100%;
}

.field-row-msis-id .search-button {
  margin-top: 24px;
  margin-left: 24px;
}

.field-row-msis-id .search-message {
  margin-top: 24px;
  margin-left: 24px;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--negative--red);
}

.field-row.field-row-msis-search-results {
  grid: auto-flow / 2fr 2fr 2fr 1fr 1fr;
  display: grid;
  width: 100%;
}

.field-row.field-row-panel-details {
  grid: auto-flow / 2fr 2fr 3fr 1fr 1fr;
  display: grid;
  width: 100%;
}

.field-row.field-row-panel {
  grid: auto-flow / 5fr 3fr 2fr;
  display: grid;
  width: 100%;
}

.field-row-panel .panel-details {
  margin-left: 24px;
  padding-left: 24px;
}
